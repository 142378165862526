import { VirtueSchema } from "./VirtueSchema"

const SCHEMA_TOM = {
  version: 1,
  virtues: [
    {
      acronym: "Re",
      description:
        "Resolve to perform what you ought; perform without fail what you resolve.",
      name: "Resolution",
    },
    {
      acronym: "Co",
      description:
        "Compassion goes beyond empathy, which is merely understanding another’s suffering; compassion actively motivates a person to take steps toward alleviating that suffering.",
      name: "Compassion",
    },
    {
      acronym: "H",
      description:
        "Focus on forming (atomic) habits to continuously and consistently improve your behaviour every day a little to become someone exceptional.",
      name: "Habitual",
    },
    {
      acronym: "Te",
      description: "No man is free who is not master of himself.",
      name: "Temperance",
    },
    {
      acronym: "P",
      description:
        "Do not be carried away by imagination, but turn aside to some immediate task, and, serious and unadorned, finish this in accordance with right and in a way that is unaffected by whether it is cold or warm, or pleasant or wretched. Do not allow your mind to be sidetracked by the appearance of things, but stay with what is real and true.",
      name: "Presence",
    },
    {
      acronym: "Tr",
      description:
        "Be like the cliff against which the waves continually break; but it stands firm and tames the fury of the water around it.",
      name: "Tranquility",
    },
    {
      acronym: "G",
      description:
        "When you arise in the morning, think of what a precious privilege it is to be alive—to breathe, to think, to enjoy, to love.",
      name: "Gratitude",
    },
    {
      acronym: "Se",
      description:
        "Consider this like resistance training - the longer you can resist reacting, the more mental space you have for actual reflection, and the stronger your mind will become. Master your emotional self.",
      name: "Self-control",
    },
    {
      acronym: "A",
      description:
        "He accustomed them to be satisfied with a little, not to want luxuries, to endure hardship, to win honor through deeds, not words.",
      name: "Austerity",
    },
    {
      acronym: "F",
      description:
        "The resistance that you fight physically in the gym and the resistance that you fight in life can only build a strong character.",
      name: "Fitness",
    },
    {
      acronym: "Ra",
      description:
        "I constantly see people rise in life who are not the smartest—sometimes not even the most diligent. But they are learning machines. They go to bed every night a little wiser than they were when they got up, and boy does that help, particularly when you have a long run ahead of you.",
      name: "Rationality",
    },
    {
      acronym: "Si",
      description:
        "The more urgently you want to speak, the more likely it is that you will say something foolish.",
      name: "Silence",
    },
    {
      acronym: "O",
      description:
        "Let all your things have their places; let each part of your business have its time.",
      name: "Order",
    },
    {
      acronym: "I",
      description:
        "Lose no time; be always employ’d in something useful; cut off all unnecessary actions.",
      name: "Industry",
    },
    {
      acronym: "C",
      description: "Tolerate no uncleanliness in body, cloaths, or habitation.",
      name: "Cleanliness",
    },
  ],
}

const SCHEMA_VERONIKA = {
  version: 1,
  virtues: [
    {
      acronym: "",
      description: "",
      name: "",
    },
  ],
}

const EMPTY_SCHEMA: VirtueSchema = {
  version: 1,
  virtues: [
    {
      acronym: "E",
      description: "EMPTY DESCRIPTION",
      name: "EMPTY SCHEMA VIRTUE",
    },
  ],
}

export function getSchemaModel(userEmail: string): VirtueSchema {
  switch (userEmail) {
    case "tomgijselinck@gmail.com":
      return SCHEMA_TOM
    case "veronika.jercic@gmail.com":
      return SCHEMA_VERONIKA
    default:
      return EMPTY_SCHEMA
  }
}
