import { JournalEntry } from "./JournalEntry"

export type DateString = string
export type Journal = Map<DateString, JournalEntry>

// Note that if a different virtues schema is provided than possible existing entries, the old entries will be overwritten
// Assumption: this function will only be used on the current day
export function addVirtueToJournalEntry(
  journal: Journal,
  virtueName: string,
  date: Date,
  virtueSchemaVersion: number,
): void {
  const dateString = journalDateString(date)
  let entry = getJournalEntry(journal, date)
  if (entry == null || entry.virtueSchemaVersion != virtueSchemaVersion) {
    entry = {
      virtueSchemaVersion,
      virtues: [virtueName],
    }
  } else {
    const byName = (name: string) => name == virtueName
    const existingEntry = entry.virtues.find(byName)
    if (existingEntry != undefined) {
      throw new Error(
        "Virtue already entered in the given date's journal entry",
      )
    }
    entry.virtues.push(virtueName)
  }
  journal.set(dateString, entry)
}

export function createJournal(): Journal {
  return new Map<DateString, JournalEntry>()
}

export function getJournalEntry(
  journal: Journal,
  date: Date,
): JournalEntry | null {
  const dateString = journalDateString(date)
  const entry = journal.get(dateString)
  if (entry == undefined) {
    return null
  } else {
    return entry
  }
}

export function parseJournalDate(dateString: string): Date {
  return new Date(dateString)
}

export function journalDateString(date: Date): DateString {
  return date.toDateString()
}
