import {
  DateString,
  Journal,
  addVirtueToJournalEntry,
  createJournal,
  journalDateString,
  parseJournalDate,
} from "./journal"
import {
  Firestore,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore"
import { FirebaseApp } from "firebase/app"
import { JournalEntry } from "./JournalEntry"
import firebase from "firebase/compat"
import { getCurrentUserUID } from "./auth"
import { weekDays } from "./helpers"
import DocumentData = firebase.firestore.DocumentData

export function cacheJournal(journal: Journal): void {
  const journalEntries = Array.from(journal)
  const stringifiedValue = JSON.stringify(journalEntries)
  localStorage.setItem(JOURNAL_STORAGE_KEY, stringifiedValue)
}

export function cacheMarkedVirtues(entries: string[]): void {
  const stringifiedValue = JSON.stringify(entries)
  localStorage.setItem(MARKED_VIRTUES_STORAGE_KEY, stringifiedValue)
}

export function cacheUserEmail(email: string) {
  localStorage.setItem(USER_EMAIL_STORAGE_KEY, email)
}

export function getCachedJournal(): Journal {
  const stringifiedValue = localStorage.getItem(JOURNAL_STORAGE_KEY)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const parsedJson =
    stringifiedValue != null ? JSON.parse(stringifiedValue) : null
  return parsedJson != null
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      new Map<DateString, JournalEntry>(parsedJson)
    : new Map<DateString, JournalEntry>()
}

export function getCachedMarkedVirtues(): string[] {
  const stringifiedValue = localStorage.getItem(MARKED_VIRTUES_STORAGE_KEY)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const parsedJson =
    stringifiedValue != null ? JSON.parse(stringifiedValue) : null
  return parsedJson != null ? (parsedJson as string[]) : []
}

export function getCachedUserEmail(): string {
  const email = localStorage.getItem(USER_EMAIL_STORAGE_KEY)
  return email != null ? email : ""
}

export async function retrieveJournal(app: FirebaseApp): Promise<Journal> {
  const rawJournal = await retrieveRawJournal(app)
  const journal = createJournal()
  if (rawJournal.size == 0) {
    return journal
  }
  const parseJournalEntries = (
    journalEntry: RawJournalEntry,
    dateString: DateString,
  ) => {
    const addToJournal = (virtueName: string) => {
      const date = parseJournalDate(dateString)
      addVirtueToJournalEntry(
        journal,
        virtueName,
        date,
        journalEntry.virtueSchemaVersion,
      )
    }
    journalEntry.virtues.forEach(addToJournal)
  }
  rawJournal.forEach(parseJournalEntries)
  return journal
}

export async function storeJournalEntry(
  virtues: string[],
  date: Date,
  virtueSchemaVersion: number,
  app: FirebaseApp,
): Promise<void> {
  const db = getFirestore(app)
  const userUID = getCurrentUserUID()
  if (userUID == null) {
    throw Error(
      "user not logged in, cannot store journal entry, please try again",
    )
  }
  const dateString = journalDateString(date)
  const reference = userJournalEntryReference(db, userUID, dateString)
  const data: RawJournalEntry = {
    virtueSchemaVersion,
    virtues,
  }
  await setDoc(reference, data)
}

const JOURNAL_STORAGE_KEY = "journal"
const MARKED_VIRTUES_STORAGE_KEY = "marked-virtues"
const USER_EMAIL_STORAGE_KEY = "user-email"

type RawJournal = Map<DateString, RawJournalEntry>

type RawJournalEntry = {
  readonly virtues: string[]
  readonly virtueSchemaVersion: number
}

function parseRawEntry(data: DocumentData): RawJournalEntry {
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    virtueSchemaVersion: data["virtueSchemaVersion"],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    virtues: data["virtues"],
  }
}

// Returns the journal entries of this week
async function retrieveRawJournal(app: FirebaseApp): Promise<RawJournal> {
  let userUID = getCurrentUserUID()
  let counter = 0
  while (userUID == null && counter < 10) {
    counter++
    await new Promise((r) => setTimeout(r, 100))
    userUID = getCurrentUserUID()
  }
  if (userUID == null) {
    throw Error("user not logged in, cannot retrieve journal entry")
  }
  const today = new Date()
  const days = weekDays(today)
  const db = getFirestore(app)
  const journal = new Map<DateString, RawJournalEntry>()
  for (const day of days) {
    const dateString = journalDateString(day)
    const reference = userJournalEntryReference(db, userUID, dateString)
    const snapshot = await getDoc(reference)
    const exists = snapshot.exists()
    if (exists) {
      const data = snapshot.data()
      const entry = parseRawEntry(data)
      journal.set(dateString, entry)
    }
  }
  return journal
}

function userJournalEntryReference(
  db: Firestore,
  userUID: string,
  date: string,
) {
  return doc(db, "users", userUID, "journalEntries", date)
}
