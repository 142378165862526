export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(`Expected 'val' to be defined`)
  }
}

export function weekDays(day: Date): Date[] {
  const monday = getMonday(day)
  const mondayDayOfMonth = monday.getDate()
  const days: Date[] = []
  for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
    const date = new Date(monday) // To avoid mutating the monday variable
    date.setDate(mondayDayOfMonth + dayOfWeek)
    days.push(date)
  }
  return days
}

function getMonday(day: Date): Date {
  const dayOfWeek = day.getDay() // 0 for Sunday, 1 for Monday, ... 6 for Saturday
  const dayOfMonth = day.getDate()
  const diff = (dayOfWeek + 6) % 7
  const date = new Date(day) // To avoid mutating the day variable
  date.setDate(dayOfMonth - diff)
  date.setHours(0, 0, 0, 0)
  return date
}
