import { Virtue } from "./virtue"
import { assertIsDefined } from "./helpers"
import { getSchemaModel } from "./virtueSchemaModel"

export type VirtueSchema = {
  readonly version: number
  readonly virtues: Virtue[]
}

export function getLatestVirtueSchemaVersion(userEmail: string): number {
  const schema = getVirtueSchema(userEmail)
  return schema.version
}

export function getVirtueOfTheWeek(day: Date, userEmail: string): Virtue {
  const virtues = getVirtues(userEmail)
  const startDate = new Date("2024-12-02")
  const diff = day.getTime() - startDate.getTime()
  const virtueIndex = Math.floor(diff / (1000 * 3600 * 24 * 7)) % virtues.length
  const virtue = virtues[virtueIndex]
  assertIsDefined(virtue)
  return virtue
}

export function getVirtues(userEmail: string): Virtue[] {
  const schema = getVirtueSchema(userEmail)
  return schema.virtues
}

function getVirtueSchema(userEmail: string): VirtueSchema {
  return getSchemaModel(userEmail)
}
